body {
  font-family: 'Roboto', sans-serif;
}

.App {
  position: relative; /* Make the .App container relative */
  overflow: hidden; /* Ensure no overflow */
  background-image: url("../src/bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.App-logo {
  height: 50vmin;
  pointer-events: none;
}
img{
  pointer-events: none;
}

/*--------------------------------------------------------------
# Heading
--------------------------------------------------------------*/
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 { font-weight: 600 !important; }
h1, .h1 { font-size: 2.5em; } h2, .h2 { font-size: 2em; } h3, .h3 { font-size: 1.75em; } h4, .h4 { font-size: 1.5em; } h5, .h5 { font-size: 1.25em; } h6, .h6 { font-size: 1em; }

/*--------------------------------------------------------------
# Home Header
--------------------------------------------------------------*/
.App-header{
  background-image: url(../src/images/home-header-bg.jpg);
  background-size: 100% 85%;
  background-repeat: no-repeat;
  background-position: top center;
  color: #ffffff;
  padding-top: 7%;
}
.App-header h1{
  font-weight: 700 !important;
}
.App-header .buttons-group{
  margin-top: 1.5rem;
}
.App-header .laptop{
  position: relative;
}
.App-header .laptop img{
  width: 100%;
}
.App-header .laptop .frontDesk{
  left: 11%;
  bottom: 25%;
  transform: rotate(-7deg);
  transition: transform 0.5s ease;
}
.App-header .laptop .dashboard{
  right: 11%;
  bottom: 15%;
  transform: rotate(10deg);
  transition: transform 0.5s ease;
}
.App-header .laptop .multiBranches{
  right: 11%;
  top: 20%;
  transform: rotate(-10deg);
  transition: transform 0.5s ease;
}
.App-header .laptop .card {
  position: relative;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.App-header .laptop .card:hover {
  background: #fff ;
  color: var(--bs-body-color);
}
.App-header .laptop .card .card-body .card-title {
  font-weight: 600 !important;
}
.App-header .laptop .card .card-body .card-svgicone {
  width: 50px;
  margin: 0 auto;
}
.App-header .laptop .frontDesk .card .card-body .card-svgicone {
  color: #007219;
  fill: #007219;
}
.App-header .laptop .multiBranches .card .card-body .card-svgicone {
  color: #252B66;
  fill: #252B66;
}
.App-header .laptop .dashboard .card .card-body .card-svgicone {
  color: #F6881F;
  fill: #F6881F;
}


.App-header .laptop .card::before {
  content: '';
  position: absolute;
  top: 10px;
  right: 10px;
  width: 8px;
  height: 8px;
  background-color: red; /* Change this color as needed */
  border-radius: 50%;
}
.App-header .laptop .frontDesk .card::before{
  background-color: #007219;
}
.App-header .laptop .multiBranches .card::before{
  background-color: #252B66;
}
.App-header .laptop .dashboard .card::before{
  background-color: #F6881F;
}


.App-header .whatsapp-btn {
  background: linear-gradient(to bottom right, #00c13e, #008530);
  padding: 15px 25px;
  border-radius: 12px;
  color: #ffffff;
  font-weight: bold;
  text-decoration: none;
}
.App-header .whatsapp-btn img {
  width: 6vmin;
}
.App-header .headerImage {
  background-image: url(./images/headerimg-bg.png);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
}

.App-header .headerImage .card-title{
  font-size: 1rem;
  font-weight: 700 !important;
  margin-bottom: 0;
}
.App-header .headerImage .card {
  position: relative;
}
.App-header .headerImage .card .card-icon {
  width: 50px;
  height: 50px;
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translate(-50%, -50%);
}
.App-header .headerImage p:last-child, .App-header .headerImage .card-title:last-child{
  margin-bottom: 0;
}

.App-header .headerImage .multibrances{
  right: -5%;
  top: 30%;
  transform: translate(-10%, -10%);
}
.App-header .headerImage .userfriendly{
  top: 12%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.App-header .headerImage .cloudstorage{
  left: 4%;
  top: 32%;
}
.App-header .headerImage .mobileapp{
  left: 50%;
  top: 70%;
  transform: translate(-10%, -10%);
}

/*--------------------------------------------------------------
# Social Media
--------------------------------------------------------------*/
.social {
  list-style-type: none;
  margin-bottom: 0;
}
.social li {
  float: left;
  margin-right: 7px;
  padding: 5px;
  border: 1px solid #ffffff;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 1.1rem;
  font-size: 1rem;
}
.social li:hover {
  background-color: #f6881f;
}
.social li a {
  color: var(--white);
  text-decoration: none;
  margin-bottom: 5px;
}
/*--------------------------------------------------------------
#Buttons
--------------------------------------------------------------*/
.btn{
  border-radius: 30px !important;
  padding: 10px 20px;
}
.btn-warning {
  color: #ffffff!important;
  background-color: #f6881f !important;
  border-color: #f6881f !important;
}

.btn-warning:hover {
  color: #ffffff!important;
  background-color: #ffca2c;
  border-color: #ffc720;
}

.btn-warning:focus {
  color: #ffffff!important;
  background-color: #f6881f !important;
  border-color: #f6881f !important;
  box-shadow: 0 0 0 0.2rem rgba(217, 164, 6, 0.5);
}

.btn-warning:active,
.btn-warning.active {
  color: #ffffff!important;
  background-color: #ffcd39;
  border-color: #ffc720;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn-warning:disabled,
.btn-warning.disabled {
  color: #ffffff!important;
  background-color: rgb(246, 136, 31) !important;
  border-color: #f6881f !important;
}



/*--------------------------------------------------------------
#WhatsAPP
--------------------------------------------------------------*/
.whatsapp-button {
  background-color: #25d366;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 25px;
  transition-duration: 0.3s;
  display: flex;
  align-items: center;
  animation: zoomInOut 2s infinite alternate;
  position: fixed;
  left: 1rem;
  z-index: 1000;
  bottom: 1rem;
  box-shadow: 0 1px 2px #00000080;
}

.whatsapp-button:hover {
  background-color: #128c7e;
  color: white;
}

.whatsapp-button svg {
  margin-right: 10px;
  font-size: 20px;
}

/* Keyframes for zoomInOut animation */
@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
/*--------------------------------------------------------------
#Scroll To Top
--------------------------------------------------------------*/
.scroll-to-top {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 1000;
}

.scroll-to-top-button {
  background-color: #f6891f;
  border: none;
  border-radius: 6px;
  color: white;
  cursor: pointer;
  padding: 5px 15px;
  font-size: 20px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  transition: opacity 0.4s;
}

.scroll-to-top-button:hover {
  background-color: #232a67;
}
/*--------------------------------------------------------------
#Navigation
--------------------------------------------------------------*/
.navbar{
  border-bottom: 1px solid rgba(255, 255, 255, 0.225);
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.05);
}
.navbar-brand  {
  width: 240px;
  background-image: url(./logowhite.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  display: block;
  height: 50px;
}
.navbar-scroll .navbar-brand  {
  background-image: url(./logo.svg);
}
.navbar-brand svg {
  width: 100%;
}
.navbar-scroll.navbar-expand-lg .navbar-nav .nav-link {
  font-size: 17px;
  color: #252b66;
}
.navbar-expand-lg .navbar-nav .nav-link {
  font-size: 17px;
  color: #ffffff;
}
.navbar-expand-lg .navbar-nav .nav-link.btn-warning  {
  background-color: #ffc107;
  color: #fff;
  padding-left: 1.3rem;
  padding-right: 1.3rem;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
    position: relative;
  }
}

/* .dropdown, .dropdown-center, .dropend, .dropstart, .dropup, .dropup-center{
  position: static !important;
} */
.navbar-scroll {
  background-color: #fff; /* Customize the background */
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Optional: Add shadow */
}

/* Mega menu style */
.mega-menu .dropdown-menu {
  width: 100%; /* Full width */
  left: 0;
  right: 0;
  top: 100%; /* Position the dropdown below the navbar */
  padding: 1rem;
  border-top: 2px solid #f8f9fa; /* Optional: Top border to separate */
}

.mega-menu .dropdown-item {
  padding: 10px;
  color: #333;
}

.mega-menu .dropdown-item:hover {
  background-color: #f8f9fa;
}

/* Columns in mega menu */
.mega-menu .container {
  display: flex;
  flex-wrap: wrap;
}

.mega-menu .col-md-3, .mega-menu .col-md-6 {
  padding: 10px;
}

@media (max-width: 991px) {
  /* Collapse dropdown on small screens */
  .mega-menu .dropdown-menu {
    width: auto; /* Reset width */
    position: static;
  }
}
.dropdown-menu .row{
  display: flex;
  width: 100%;
}
.dropdown-item::before {
  content: "\2022"; 
  display: inline-block;
  margin-right: 8px;
  color: #f6881f; 
  font-size: 1.2em;
  vertical-align: baseline; 
}

.no-caret .dropdown-toggle::after {
  display: none;
}
.user-login {
  border-left: 1px solid rgba(0, 0, 0, 0.26);
  margin-left: 15px;
  padding-left: 7px;
}
.user-login a {
  padding: 0;
}
.user-login a.dropdown-item {
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
}
.user-login a svg {
  color: #f6881f;
  border: 1px solid #f6881f;
  border-radius: 100%;
  padding: 0.5rem;
  width: 1rem;
  height: 1rem;
}

/*--------------------------------------------------------------
#Section
--------------------------------------------------------------*/
section {
  padding: 80px 0;
}

/*--------------------------------------------------------------
#No Page
--------------------------------------------------------------*/
.no-page {
  text-align: center;
  padding: 50px;
}

.no-page h1 {
  font-size: 100px;
  margin-bottom: 20px;
}

.no-page p {
  font-size: 24px;
  margin-bottom: 20px;
}

.no-page a {
  font-size: 18px;
  color: #252b66;
  text-decoration: none;
}

.no-page a:hover {
  text-decoration: underline;
}

/*--------------------------------------------------------------
# Content Body
--------------------------------------------------------------*/
.content-body {
  min-height: 100vh;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer {
  display: flex;
  justify-content: space-around;
  background-color: #010b6a;
  color: #fff;
  padding: 70px 0 40px 0;
}

.footer .footer-logo {
  width: 70%;
  max-width: 220px;
}

.footer h4 {
  margin-bottom: 10px;
}

.footer ul {
  list-style: none;
  padding: 0;
}

.footer li {
  margin-bottom: 10px;
}
.footer a {
  color: #fff;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}
.footer h4 {
  color: #ffffff !important;
}
.copyright {
  background-color: #030b5a;
  color: #fff;
}
.copyright a {
  color: #fff;
  text-decoration: none;
}

/*--------------------------------------------------------------
# Clients
--------------------------------------------------------------*/
.clients-slider {
  padding: 15px 0;
  text-align: center;
}

.clients-slider img {
  transition: all 0.4s ease-in-out;
  display: inline-block;
  padding: 15px 0;
}
.clients img {
  max-width: 45%;
}
.clients-slider img {
  max-width: 75%;
}

.clients-slider img:hover {
  transform: scale(1.15);
}

@media (max-width: 768px) {
  .clients img {
    max-width: 60%;
  }
  .clients-slider img {
    max-width: 80%;
  }
}

/*--------------------------------------------------------------
# Features
--------------------------------------------------------------*/
.features {
  position: relative;
  background: linear-gradient(to bottom, rgb(29, 36, 95) 78%, white 78%);

  color: #ffffff;
}
.features .card {
  background-color: #fbfdfe;
  transition: background 0.5s ease;
}
.features .card .card-body {
  justify-content: center;
}
.features .card .card-icon {
  width: 80px;
  height: 80px;
  margin: 3px auto;
  background-color: rgba(246, 135, 31, 0.1);
  border-radius: 100%;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.03);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: #F6881F;
  fill: #F6881F;

}

/*--------------------------------------------------------------
# Card
--------------------------------------------------------------*/
.card {
  background-color: #fbfdfe !important;
  border-radius: 17px !important;
  box-shadow: -3px 3px 25px rgba(0, 0, 0, 0.1);
  border: 0 !important;
  transition: background 0.5s ease;
}

.card .card-body {
  justify-content: center;
}
.card .card-icon {
  width: 80px;
  height: 80px;
  margin: 3px auto;
  background-color: rgba(246, 135, 31, 0.10);
  border-radius: 100%;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.03);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: rgba(246, 135, 31);
  fill: rgba(246, 135, 31);
}
.card .card-icon svg{
  width: 65%;
  fill: rgba(246, 135, 31);
}
.card:hover {
  background: linear-gradient(to right, rgb(238, 250, 255) 50%, white 50%);
}
.card .card-body .card-title {
  font-weight: 600;
  text-transform: capitalize;
}
.card .card-body .sub-title{
  font-weight: 500;
  font-size: 1rem;
}
.card .card-body .card-description{
  font-size: 0.85rem;
}
.card .card-body .card-link{
  color: var(--bs-card-title-color);
  text-decoration: none;
}
.card .card-body .link-icon{
  width: 15px;
  fill: var(--bs-card-title-color);
  margin-left: 5px;
}
.card .card-body .card-list {
  padding-left: 15px !important;
}
/*--------------------------------------------------------------
# trustedBy
--------------------------------------------------------------*/
.trustedBy h4 {
  font-family: Arial, Helvetica, sans-serif;
}
.trustedBy .card:hover{
  background: inherit !important;
  color: inherit !important;
}

/*--------------------------------------------------------------
# Heading
--------------------------------------------------------------*/
.heading .heading-slogan {
  color: rgb(246, 136, 31);
  background-color: rgb(246, 136, 31, 0.125);
  display: inline-block;
  border-radius: 50px;
  padding: 0.25rem 0.75rem;
  margin-bottom: 0;
  font-weight: 600;
}
.requestTrial {
  background-color: #0E9AE8;
  color: #fff;
}
section.requestTrial {
  padding: 40px 0;
}


/*--------------------------------------------------------------
# Accordion
--------------------------------------------------------------*/
.accordion-item:first-of-type > .accordion-header .accordion-button{
  background-color: rgb(29, 36, 95, 0.10);
  border-radius: 50px 50px;
}

.accordion-item:first-of-type > .accordion-header .accordion-button{
  border-top-left-radius: 50px !important;
  border-top-right-radius:50px !important;
}
.accordion-item .accordion-header .accordion-button{
  border-radius: 50px !important;
  background-color: rgb(29, 36, 95, 0.10);
}
.accordion-item:first-of-type > .accordion-header .accordion-button{
  background-color: rgb(29, 36, 95, 0.10);
  border-radius: 50px 50px;
}
.accordion-item{
  margin-bottom: 10px;
  border: none !important;
  
}
/*--------------------------------------------------------------
# FulfillRequirement
--------------------------------------------------------------*/
.fulfillRequirement {
  position: relative;
}

/*--------------------------------------------------------------
# WhyJEMS
--------------------------------------------------------------*/
.whyJEMS {
  position: relative;
  background-image: url("images/laptop.png");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 50% 100%;
  padding: 2rem 0;
}
.navbar-nav .dropdown-menu {
  width: auto; /* Adjust width as needed */
  padding: 10px;
}

.dropdown-header {
  font-weight: bold;
}
/*--------------------------------------------------------------
# AboutJEMS
--------------------------------------------------------------*/
.about-image{
  position: relative;
  text-align: center;
}
.about-image img{ 
  margin: 0 auto;
}
.about-image .sales,
.about-image .faculty,
.about-image .fee{
  position: absolute;
  z-index: 1;
  padding: 1rem;
  border-radius: 50px;
  text-align: left;
  box-shadow: -3px 3px 25px rgba(0, 0, 0, 0.1);
  max-width: 33%;
}
.about-image .sales p,
.about-image .faculty p,
.about-image .fee p{
  margin-bottom: 0;
}
.about-image .sales h6,
.about-image .faculty h6,
.about-image .fee h6{
  margin-bottom: 0;
}
.about-image .sales{
  top: 5%;
  right: 20px;
  background-color: rgb(255, 255, 255);
  border-radius: 1.5rem;
}
.about-image .faculty{
  bottom: 5%;
  right: 20px;
  background-color: rgba(37, 44, 102, 0.15);
  fill: rgba(37, 44, 102  );
}
.about-image .fee{
  top: 40%;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
  fill: #F6881F
}
.about-content .text-warning{
  color: #f6881f !important ;
}

/*--------------------------------------------------------------
# Professionals Recommending Products
--------------------------------------------------------------*/
.professionalsRecommending{
  background-image: url('./images/recommending.jpg');
  background-size: 100%; 
  background-position: center; 
  background-repeat: no-repeat; 
  padding: 50px 50px !important;
  border-radius: 17px ;
  color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.professionalsRecommending .btn-lg{
  padding: 10px 25px !important;
  font-size: calc(5px + 2vmin) !important;
}

/*--------------------------------------------------------------
# Page Header
--------------------------------------------------------------*/
.pageHeader {
  background: url(./images/home-header-bg.jpg) left no-repeat;
  background-size: cover;
  padding: 100px 0;
  color: #fff;
}
.pageHeader .container {
  padding-top: 50px;
}
/*--------------------------------------------------------------
# Page Featurs
--------------------------------------------------------------*/
.featurs, .solutions{
  text-align: center;
}
.featurs .heading,
.solutions .heading{
  margin-bottom: 15px;
}
.featurs section:nth-child(odd),
.solutions section:nth-child(odd){
  background-color: #EEFAFF;
}
.solutions section img, section img  {
  mix-blend-mode: multiply;
}

/*--------------------------------------------------------------
# Page About
--------------------------------------------------------------*/
.about img{
  width: 60%;
}
/*--------------------------------------------------------------
# Page Contact
--------------------------------------------------------------*/
.contactus a{
  text-decoration: none;
  color: var(--bs-card-color);
}
.contactus  .card:hover {
  background: rgb(255, 255, 255);
}
.contactForm .col{
  margin-bottom: 15px;
}